import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "change-language"
    }}>{`Change Language`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Patrons can change the language of their mobile app to any of the enabled lanugages within the Content Management System. `}<em parentName="li">{`To learn how to configure this, please refer to the `}<a parentName="em" {...{
            "href": ""
          }}>{`Edit Languages`}</a>{` page for more information.`}</em></li>
      <li parentName="ol">{`To change the language, patrons can select Languages from the My Account menu display. `}</li>
      <li parentName="ol">{`The list of enabled languages will display in this section. In order to change the app's language, the patron will need to select the desired language from the list and confirm the change. `}</li>
    </ol>
    <p><img alt="Languages Menu Screen Option" src={require("./images/edit_languages_menu.png")} />{` `}<img alt="Language Options Screen" src={require("./images/edit_languages_options.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      